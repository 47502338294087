.loading-section {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #1f2937;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  box-sizing: inherit;
}

.container-loading {
  width: max-content;
  max-width: 100px;
  box-sizing: inherit;
}

.loading-svg {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  fill: none;
  box-sizing: inherit;
}
